import React, { FC, ReactNode, useEffect } from 'react';
import { PostDataType, TaxonomyType } from 'data/templates/types';
import NcImage from 'components/templates/NcImage/NcImage';
import { SINGLE } from 'data/templates/single';
import SingleContent from '../Section/SingleContent';
import { CommentType } from 'components/templates/CommentCard/CommentCard';
import { useAppDispatch } from 'app/hooks';
import { changeCurrentPage } from 'app/pages/pages';
import SingleRelatedPosts from '../Section/SingleRelatedPosts';
import SingleHeader from '../Section/SingleHeader';

export interface PageSingleTemplate2Props {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  tags: TaxonomyType[];
  content: string | ReactNode;
  comments: CommentType[];
}

const PageSingleTemplate2: FC<PageSingleTemplate2Props> = ({
  className = '',
}) => {
  const dispatch = useAppDispatch();

  // UPDATE CURRENT PAGE DATA IN PAGE REDUCERS
  useEffect(() => {
    dispatch(
      changeCurrentPage({ type: '/developers/single/:slug', data: SINGLE })
    );
    return () => {
      dispatch(changeCurrentPage({ type: '/', data: {} }));
    };
  }, []);

  return (
    <>
      <div
        className={`nc-PageSingleTemplate2 pt-8 lg:pt-16 ${className}`}
        data-nc-id="PageSingleTemplate2"
      >
        {/* SINGLE HEADER */}
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <SingleHeader hiddenDesc pageData={SINGLE} />
          </div>
        </header>

        {/* FEATURED IMAGE */}
        <div className="">
          <NcImage
            containerClassName="my-10 sm:my-12 relative aspect-w-16 aspect-h-12 md:aspect-h-9 lg:aspect-h-6"
            className="absolute inset-0 object-cover w-full h-full"
            src={SINGLE.featuredImage}
          />
        </div>
        {/* SINGLE MAIN CONTENT */}
        <div className="container">
          <SingleContent data={SINGLE} />
        </div>

        {/* RELATED POSTS */}
        <SingleRelatedPosts />
      </div>
    </>
  );
};

export default PageSingleTemplate2;
