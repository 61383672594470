import React, { FC } from 'react';
import NcImage from 'components/templates/NcImage/NcImage';
import PostCardSaveAction from 'components/templates/PostCardSaveAction/PostCardSaveAction';
import { PostDataType } from 'data/templates/types';
import { Link } from 'react-router-dom';
import PostCardLikeAndComment from 'components/templates/PostCardLikeAndComment/PostCardLikeAndComment';
import CategoryBadgeList from 'components/templates/CategoryBadgeList/CategoryBadgeList';
import PostTypeFeaturedIcon from 'components/templates/PostTypeFeaturedIcon/PostTypeFeaturedIcon';
import PostFeaturedMedia from 'components/templates/PostFeaturedMedia/PostFeaturedMedia';
import { CardPostDataType } from '../../data/types';
import TagBadgeList from '../TagBadgeList';

export interface CardPost9Props {
  className?: string;
  ratio?: string;
  post: CardPostDataType;
  hoverClass?: string;
}

const CardPost9: FC<CardPost9Props> = ({
  className = 'h-full',
  ratio = 'aspect-w-5 aspect-h-5 sm:aspect-h-4',
  post,
  hoverClass = '',
}) => {
  const { id, title, createdTime, coverImageUrl, topic, tags } = post;

  const renderMeta = () => {
    return (
      <div className="inline-flex items-center text-xs text-neutral-300">
        <div className="block ">
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2" title={title}>
              {title}
            </span>
          </h2>
          <Link to="#" className="flex mt-2.5 relative">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              Philly Chien
            </span>
            <span className="mx-[6px] font-medium">·</span>
            <span className="font-normal truncate">
              {createdTime.toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              })}
            </span>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-Card9 relative flex flex-col group rounded-3xl overflow-hidden ${hoverClass} ${className}`}
      data-nc-id="Card9"
    >
      {/*<div className="absolute inset-x-0 top-0 p-3 flex items-center justify-between transition-all opacity-0 z-[-1] group-hover:opacity-100 group-hover:z-10 duration-300">*/}
      {/*  <PostCardLikeAndComment className="relative" postData={post} />*/}
      {/*  <PostCardSaveAction className="relative" postData={post} />*/}
      {/*</div>*/}
      <div className={`flex items-start relative w-full ${ratio}`} />
      <Link to={`/single/${id}`}>
        <NcImage
          containerClassName="absolute inset-0 rounded-3xl"
          className="object-cover w-full h-full rounded-3xl"
          src={coverImageUrl}
        />
        {/*<PostTypeFeaturedIcon*/}
        {/*  className="absolute top-3 left-3 group-hover:hidden"*/}
        {/*  postType={postType}*/}
        {/*  wrapSize="w-7 h-7"*/}
        {/*  iconSize="w-4 h-4"*/}
        {/*/>*/}
        <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span>
      </Link>
      <Link
        to={`/single/${id}`}
        className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black opacity-50"
      />
      <div className="absolute bottom-0 inset-x-0 p-4 flex flex-col flex-grow">
        <Link to={`/single/${id}`} className="absolute inset-0"></Link>
        <div className="mb-3">
          <TagBadgeList tags={tags} />
        </div>
        {renderMeta()}
      </div>
    </div>
  );
};

export default CardPost9;
