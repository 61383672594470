import React, { FC } from 'react';
import SingleTitle from './SingleTitle';
import PostMeta2 from 'components/PostMeta2';
import SingleMetaAction2 from './SingleMetaAction2';
import { Helmet } from 'react-helmet';
import SingleMetaAction from './SingleMetaAction';
import { SinglePostDataType } from '../../data/types';
import TagBadgeList from '../../components/TagBadgeList';

export interface SingleHeaderProps {
  pageData?: SinglePostDataType;
  hiddenDesc?: boolean;
  metaActionStyle?: 'style1' | 'style2';
  titleMainClass?: string;
  className?: string;
}

const SingleHeader: FC<SingleHeaderProps> = ({
  pageData,
  titleMainClass,
  hiddenDesc = false,
  className = '',
  metaActionStyle = 'style1',
}) => {
  const renderHeader = () => {
    if (!pageData) {
      return null;
    }

    const { tags, description, title } = pageData;
    return (
      <div className={`nc-SingleHeader ${className}`}>
        <div className="space-y-5">
          <TagBadgeList itemClass="!px-3" tags={tags} />
          {/*<CategoryBadgeList itemClass="!px-3" categories={categories} />*/}
          <SingleTitle mainClass={titleMainClass} title={title} />
          {!!description && !hiddenDesc && (
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
              {description}
            </span>
          )}
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800" />
          <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
            <PostMeta2
              size="large"
              className="leading-none flex-shrink-0"
              meta={pageData}
              hiddenCategories
              avatarRounded="rounded-full shadow-inner"
            />
            {metaActionStyle === 'style1' ? (
              <SingleMetaAction meta={pageData} />
            ) : (
              <SingleMetaAction2 meta={pageData} />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Single || Philly's Fun Blog</title>
      </Helmet>
      {renderHeader()}
    </>
  );
};

export default SingleHeader;
