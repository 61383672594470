import Avatar from 'components/templates/Avatar/Avatar';
import { PostAuthorType } from 'data/templates/types';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const SinglePhillyAuthor: FC = () => {
  return (
    <div className="nc-SingleAuthor flex">
      <Link to="#">
        <Avatar
          imgUrl="https://avatars.githubusercontent.com/u/54149482?v=4"
          userName="Philly Chien"
          sizeClass="h-12 w-12 text-lg sm:text-xl sm:h-24 sm:w-24 "
          radius="rounded-xl"
        />
      </Link>
      <div className="flex flex-col ml-3 max-w-lg sm:ml-5">
        <span className="text-xs text-neutral-400 uppercase tracking-wider">
          WRITTEN BY
        </span>
        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          <Link to="#">Philly Chien</Link>
        </h2>
        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
          There is only one heroism in the world: to see the world as it is and
          to love it.
          {/*<Link className="text-primary-600 font-medium ml-1" to={author.href}>*/}
          {/*  Readmore*/}
          {/*</Link>*/}
        </span>
      </div>
    </div>
  );
};

export default SinglePhillyAuthor;
