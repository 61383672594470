import React, { FC } from 'react';
import NcImage from 'components/templates/NcImage/NcImage';
import { TaxonomyType, TwMainColor } from 'data/templates/types';
import { Link } from 'react-router-dom';
import Badge from 'components/templates/Badge/Badge';
import { TagDataType } from '../../data/types';

export interface CardTag2Props {
  className?: string;
  tag: TagDataType;
  index?: string;
}

const CardTag2: FC<CardTag2Props> = ({ className = '', tag, index }) => {
  const { id, name, colorHex, iconURL, coverImageUrl } = tag;
  return (
    <Link
      to={`/search/tag/${id}`}
      className={`nc-CardCategory2 relative flex flex-col items-center justify-center text-center px-3 py-5 sm:p-6  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
      data-nc-id="CardCategory2"
    >
      {index && (
        <Badge
          color={'blue' as TwMainColor}
          name={index}
          className="absolute -top-2 sm:top-3 left-3"
        />
      )}
      {/*<NcImage*/}
      {/*  containerClassName={`flex-shrink-0 w-20 h-20 rounded-full overflow-hidden`}*/}
      {/*  src={coverImageUrl}*/}
      {/*/>*/}
      <NcImage
        containerClassName={`flex-shrink-0 w-20 h-20 rounded-full overflow-hidden`}
        src={iconURL}
      />
      <div className="mt-3 ">
        <h2 className={`text-base sm:text-lg font-semibold `}>
          <span className="line-clamp-1">{name}</span>
        </h2>
        {/*        <span*/}
        {/*            className={`block mt-[2px] text-sm text-neutral-500 dark:text-neutral-400`}*/}
        {/*        >*/}
        {/*  {count} Articles*/}
        {/*</span>*/}
      </div>
    </Link>
  );
};

export default CardTag2;
