import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import PageHome from 'containers/PageHome';
import PageSingle from 'containers/PageSingle';
import PageSearch from '../containers/PageSearch';
import PageTagSearch from '../containers/PageTagSearch';
import Footer from 'components/templates/Footer/Footer';
import Page404 from 'containers/Page404/Page404';
import PageArchive from 'containers/PageArchive/PageArchive';
import PageAuthor from 'containers/PageAuthor/PageAuthor';
import PageAbout from 'containers/PageAbout/PageAbout';
import PageContact from 'containers/PageContact/PageContact';
import PageLogin from 'containers/PageLogin/PageLogin';
import PageSignUp from 'containers/PageSignUp/PageSignUp';
import PageForgotPass from 'containers/PageForgotPass/PageForgotPass';
import PageDashboard from 'containers/PageDashboard/PageDashboard';
import PageSubscription from 'containers/PageSubscription/PageSubscription';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import PageAuthorV2 from 'containers/PageAuthor/PageAuthorV2';
import MediaRunningContainer from 'containers/MediaRunningContainer/MediaRunningContainer';
import PageSingleGallery from 'containers/PageSingleGallery/PageSingleGallery';
import PageSingleAudio from 'containers/PageSingleAudio/PageSingleAudio';
import PageSingleVideo from 'containers/PageSingleVideo/PageSingleVideo';
import PageArchiveVideo from 'containers/PageArchive/PageArchiveVideo';
import PageArchiveAudio from 'containers/PageArchive/PageArchiveAudio';
import MediaRunningContainerForSafari from 'containers/MediaRunningContainer/MediaRunningContainerForSafari';
import isSafariBrowser from 'utils/isSafariBrowser';
import PageHomeDemo1 from 'containers/PageHome/templates/Home/PageHomeDemo1';
import PageHomeDemo2 from 'containers/PageHome/templates/Home/PageHomeDemo2';
import PageHomeDemo3 from 'containers/PageHome/templates/Home/PageHomeDemo3';
import PageHomeDemo4 from 'containers/PageHome/templates/Home/PageHomeDemo4';
import PageHomeDemo5 from 'containers/PageHome/templates/Home/PageHomeDemo5';
import PageHomeDemo6 from 'containers/PageHome/templates/Home/PageHomeDemo6';
import PageHomeDemo7 from 'containers/PageHome/templates/Home/PageHomeDemo7';
import PageSingleDemo from 'containers/PageSingle/templates/SinglePage/PageSingleDemo';
import PageSingleHasSidebar from 'containers/PageSingle/templates/SinglePage/PageSingleHasSidebar';
import PageSingleTemplate2 from 'containers/PageSingle/templates/SinglePage/PageSingleTemp2';
import PageSingleTemp2Sidebar from 'containers/PageSingle/templates/SinglePage/PageSingleTemp2Sidebar';
import PageSingleTemplate3 from 'containers/PageSingle/templates/SinglePage/PageSingleTemp3';
import PageSingleTemp3Sidebar from 'containers/PageSingle/templates/SinglePage/PageSingleTemp3Sidebar';
import PageSearchDemo1 from 'containers/PageSearch/templates/PageSearch';
import PageSearchDemo2 from 'containers/PageSearch/templates/PageSearchV2';

export const pages: Page[] = [
  { path: '/', exact: true, component: PageHome },
  { path: '/#', exact: true, component: PageHome },
  { path: '/single/:singleId', component: PageSingle },
  { path: '/search/tag/:tagId', component: PageTagSearch },
  // { path: '/search', component: PageSearch },
  // { path: '/search-v2', component: PageSearchV2 },
  //
  { path: '/archive/:slug', component: PageArchive },
  { path: '/archive-video/:slug', component: PageArchiveVideo },
  { path: '/archive-audio/:slug', component: PageArchiveAudio },
  //
  { path: '/author/:slug', component: PageAuthor },
  { path: '/author-v2/:slug', component: PageAuthorV2 },
  { path: '/about', component: PageAbout },
  { path: '/contact', component: PageContact },
  { path: '/page404', component: Page404 },
  { path: '/login', component: PageLogin },
  { path: '/signup', component: PageSignUp },
  { path: '/forgot-pass', component: PageForgotPass },
  { path: '/dashboard', component: PageDashboard },
  { path: '/subscription', component: PageSubscription },
  // templates
  // HOME PAGE
  { path: '/developers', exact: true, component: PageHomeDemo1 },
  { path: '/developers/#', exact: true, component: PageHomeDemo1 },
  { path: '/developers/home-demo-2', component: PageHomeDemo2 },
  { path: '/developers/home-demo-3', component: PageHomeDemo3 },
  { path: '/developers/home-demo-4', component: PageHomeDemo4 },
  { path: '/developers/home-demo-5', component: PageHomeDemo5 },
  { path: '/developers/home-demo-6', component: PageHomeDemo6 },
  { path: '/developers/home-demo-7', component: PageHomeDemo7 },
  // SINGLE PAGE
  { path: '/developers/single/:slug', component: PageSingleTemp3Sidebar },
  {
    path: '/developers/single-sidebar/:slug',
    component: PageSingleTemplate3,
  },
  {
    path: '/developers/single-template-2/:slug',
    component: PageSingleTemplate2,
  },
  {
    path: '/developers/single-2-sidebar/:slug',
    component: PageSingleTemp2Sidebar,
  },
  {
    path: '/developers/single-template-3/:slug',
    component: PageSingleDemo,
  },
  {
    path: '/developers/single-3-sidebar/:slug',
    component: PageSingleHasSidebar,
  },
  {
    path: '/developers/single-gallery/:slug',
    component: PageSingleGallery,
  },
  {
    path: '/developers/single-audio/:slug',
    component: PageSingleAudio,
  },
  {
    path: '/developers/single-video/:slug',
    component: PageSingleVideo,
  },
  { path: '/developers/search', component: PageSearchDemo1 },
  { path: '/developers/search-v2', component: PageSearchDemo2 },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HeaderContainer />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
      {/* MEDIA */}

      {/* //is Safari on an apple touch-screen device */}
      {isSafariBrowser() ? (
        <MediaRunningContainerForSafari />
      ) : (
        <MediaRunningContainer />
      )}
    </BrowserRouter>
  );
};

export default Routes;
