import CardLarge1 from 'components/CardLarge1';
import Heading from 'components/templates/Heading/Heading';
import { TopicDataType } from 'data/types';
import React, { FC, useState } from 'react';

export interface SectionTopicsSliderProps {
  className?: string;
  heading?: string;
  topics: TopicDataType[];
}

const SectionTopicsSlider: FC<SectionTopicsSliderProps> = ({
  topics,
  heading = "Editor's pick",
  className = '',
}) => {
  const [indexActive, setIndexActive] = useState(0);

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= topics.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return topics.length - 1;
      }
      return state - 1;
    });
  };

  return (
    <div className={`nc-SectionLargeSlider relative ${className}`}>
      {!!heading && <Heading>{heading}</Heading>}
      {topics.map((item, index) => (
        <CardLarge1
          key={index}
          isShowing={indexActive === index}
          onClickNext={handleClickNext}
          onClickPrev={handleClickPrev}
          topic={item}
        />
      ))}
    </div>
  );
};

export default SectionTopicsSlider;
