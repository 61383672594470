import { CardPostDataType } from 'data/types';
import React, { FC } from 'react';
import Badge from '../Badge';

export interface TagBadgeListProps {
  className?: string;
  itemClass?: string;
  tags: CardPostDataType['tags'];
}

const TagBadgeList: FC<TagBadgeListProps> = ({
  className = 'flex flex-wrap space-x-2',
  itemClass,
  tags,
}) => {
  return (
    <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
      {tags.map((item) => (
        <Badge
          className={itemClass}
          key={item.id}
          name={item.name}
          href={`/search/tag/${item.id}`}
          color="red"
        />
      ))}
    </div>
  );
};

export default TagBadgeList;
