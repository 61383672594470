import { CustomLink } from 'data/templates/types';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import twFocusClass from 'utils/twFocusClass';

const DEMO_PAGINATION: CustomLink[] = [
  {
    label: '1',
    href: '#',
  },
  {
    label: '2',
    href: '#',
  },
  {
    label: '3',
    href: '#',
  },
  {
    label: '4',
    href: '#',
  },
];

export interface PaginationProps {
  className?: string;
}

const Pagination: FC<PaginationProps> = ({ className = '' }) => {
  const renderItem = (pag: CustomLink, index: number) => {
    if (index === 0) {
      // Return Inactive Pagination
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-600 text-white ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    }
    // Return Inactive Pagination
    return (
      <Link
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-600 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        to={pag.href}
      >
        {pag.label}
      </Link>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {DEMO_PAGINATION.map(renderItem)}
    </nav>
  );
};

export default Pagination;
