import React, { FC, useEffect, useState } from 'react';
import { DEMO_POSTS } from 'data/templates/posts';
import { PostDataType } from 'data/templates/types';
import Pagination from 'components/templates/Pagination/Pagination';
import ButtonPrimary from 'components/templates/Button/ButtonPrimary';
import Nav from 'components/templates/Nav/Nav';
import NavItem from 'components/templates/NavItem/NavItem';
import ArchiveFilterListBox from 'components/templates/ArchiveFilterListBox/ArchiveFilterListBox';
import Input from 'components/templates/Input/Input';
import { Helmet } from 'react-helmet';
import SectionSubscribe2 from 'components/templates/SectionSubscribe2/SectionSubscribe2';
import NcImage from 'components/templates/NcImage/NcImage';
import NcLink from 'components/templates/NcLink/NcLink';
import SectionSliderNewAuthors from 'components/templates/SectionSliderNewAthors/SectionSliderNewAuthors';
import { DEMO_AUTHORS } from 'data/templates/authors';
import ButtonSecondary from 'components/templates/Button/ButtonSecondary';
import { DEMO_CATEGORIES } from 'data/templates/taxonomies';
import SectionGridCategoryBox from 'components/templates/SectionGridCategoryBox/SectionGridCategoryBox';
import BackgroundSection from 'components/templates/BackgroundSection/BackgroundSection';
import Card11 from 'components/templates/Card11/Card11';
import ButtonCircle from 'components/templates/Button/ButtonCircle';
import CardCategory2 from 'components/templates/CardCategory2/CardCategory2';
import Tag from 'components/templates/Tag/Tag';
import CardAuthorBox2 from 'components/templates/CardAuthorBox2/CardAuthorBox2';
import { useParams } from 'react-router-dom';
import { CardPostDataType, TagDataType } from '../../data/types';
import { getPostsByPagination, getTags } from '../../apis/cactus';
import CardPost11 from '../../components/CardPost/CardPost11';

export interface PageSearchProps {
  className?: string;
}

const posts: PostDataType[] = DEMO_POSTS.filter((_, i) => i < 12);
const cats = DEMO_CATEGORIES.filter((_, i) => i < 15);
const tags = DEMO_CATEGORIES.filter((_, i) => i < 32);
const authors = DEMO_AUTHORS.filter((_, i) => i < 12);

const FILTERS = [
  { name: 'Most Recent' },
  { name: 'Curated by Admin' },
  { name: 'Most Appreciated' },
  { name: 'Most Discussed' },
  { name: 'Most Viewed' },
];

const TABS = ['Articles', 'Categories', 'Tags', 'Authors'];

const PageTagSearch: FC<PageSearchProps> = ({ className = '' }) => {
  const { tagId } = useParams<{ tagId: string }>();
  const [tag, setTag] = useState<TagDataType | undefined>(undefined);
  useEffect(() => {
    (async () => {
      try {
        const data = await getTags(tagId);
        const tempArr = data.map<TagDataType>((item) => ({
          id: item.id,
          name: item.name,
          iconURL: item.iconURL,
          coverImageUrl: item.coverImage
            ? item.coverImage.url + '?w=1000'
            : undefined,
          colorHex: item.color.hex,
        }));
        setTag(tempArr[0]);
      } catch (err) {
        setTag(undefined);
        console.log(err);
      }
    })();
  }, []);
  const [result, setResult] = useState<
    | {
        data: CardPostDataType[];
        size: number;
      }
    | undefined
  >(undefined);
  const [page, setPage] = useState(1);
  const itemsMaximum = 10;
  useEffect(() => {
    if (!tag) {
      setResult(undefined);
      return;
    }
    (async () => {
      try {
        const data = await getPostsByPagination(
          {
            from: (page - 1) * itemsMaximum,
            size: itemsMaximum,
          },
          { tagId: tag?.id },
          {
            byField: '_createdAt',
            inDescending: true,
          }
        );
        const tempArr = data.data.map<CardPostDataType>((item) => ({
          id: item.id,
          title: item.title,
          createdTime: new Date(item.createdAt),
          coverImageUrl: item.coverImage
            ? item.coverImage.url + '?h=650'
            : undefined,
          topic: item.topic
            ? {
                id: item.topic.id,
                name: item.topic.name,
              }
            : undefined,
          tags: item.tags
            ? item.tags.map((i) => ({
                id: i.id,
                name: i.name,
                colorHex: i.color.hex,
              }))
            : [],
        }));
        setResult({
          data: tempArr,
          size: data.size,
        });
      } catch (err) {
        setResult(undefined);
        console.log(err);
      }
    })();
  }, [tag, page]);

  return (
    <div className={`nc-PageSearch ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Search || Philly's Fun Blog</title>
      </Helmet>

      {/* HEADER */}
      <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto">
        <div className="rounded-3xl relative aspect-w-16 aspect-h-16 sm:aspect-h-9 lg:aspect-h-5 overflow-hidden ">
          {tag ? (
            <NcImage
              containerClassName="absolute inset-0"
              src={tag.coverImageUrl}
              className="object-cover w-full h-full"
            />
          ) : (
            <NcImage
              containerClassName="absolute inset-0"
              src="https://images.pexels.com/photos/2138922/pexels-photo-2138922.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              className="object-cover w-full h-full"
            />
          )}
        </div>
        {/* CONTENT */}
        <div className="relative container -mt-20 lg:-mt-48">
          <div className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex items-center">
            <header className="w-full max-w-3xl mx-auto text-center flex flex-col items-center">
              <h2 className="text-2xl sm:text-4xl font-semibold">
                {tag ? tag.name : '??'}
              </h2>
              <span className="block text-xs sm:text-sm mt-4 text-neutral-500 dark:text-neutral-300">
                We found{' '}
                <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                  {result ? result.size : '??'}
                </strong>{' '}
                results for{' '}
                <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                  {tag ? tag.name : '??'}
                </strong>
              </span>
              {/*<form*/}
              {/*  className="relative w-full mt-8 sm:mt-11 text-left"*/}
              {/*  method="post"*/}
              {/*>*/}
              {/*  <label*/}
              {/*    htmlFor="search-input"*/}
              {/*    className="text-neutral-500 dark:text-neutral-300"*/}
              {/*  >*/}
              {/*    <span className="sr-only">Search all icons</span>*/}
              {/*    <Input*/}
              {/*      id="search-input"*/}
              {/*      type="search"*/}
              {/*      placeholder="Type and press enter"*/}
              {/*      sizeClass="pl-14 py-5 pr-5 md:pl-16"*/}
              {/*      defaultValue={s}*/}
              {/*    />*/}
              {/*    <ButtonCircle*/}
              {/*      className="absolute right-2.5 top-1/2 transform -translate-y-1/2"*/}
              {/*      size=" w-11 h-11"*/}
              {/*      type="submit"*/}
              {/*    >*/}
              {/*      <i className="las la-arrow-right text-xl" />*/}
              {/*    </ButtonCircle>*/}
              {/*    <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">*/}
              {/*      <svg width="24" height="24" fill="none" viewBox="0 0 24 24">*/}
              {/*        <path*/}
              {/*          stroke="currentColor"*/}
              {/*          strokeLinecap="round"*/}
              {/*          strokeLinejoin="round"*/}
              {/*          strokeWidth="1.5"*/}
              {/*          d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"*/}
              {/*        />*/}
              {/*      </svg>*/}
              {/*    </span>*/}
              {/*  </label>*/}
              {/*</form>*/}
              {/*<div className="w-full text-sm text-left mt-4 text-neutral-500 dark:text-neutral-300">*/}
              {/*  <div className="inline-block">*/}
              {/*    <span className="mr-2.5">Related:</span>*/}
              {/*    <NcLink className="mr-2.5 inline-block font-normal" to="/#">*/}
              {/*      Design*/}
              {/*    </NcLink>*/}
              {/*    <NcLink className="mr-2.5 inline-block font-normal" to="/#">*/}
              {/*      Photo*/}
              {/*    </NcLink>*/}
              {/*    <NcLink className="mr-2.5 inline-block font-normal" to="/#">*/}
              {/*      Vector*/}
              {/*    </NcLink>*/}
              {/*    <NcLink className="mr-2.5 inline-block font-normal" to="/#">*/}
              {/*      Frontend*/}
              {/*    </NcLink>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </header>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <main>
          {/* TABS FILTER */}
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            {/*<Nav*/}
            {/*    containerClassName="w-full overflow-x-auto hiddenScrollbar"*/}
            {/*    className="sm:space-x-2"*/}
            {/*>*/}
            {/*  {TABS.map((item, index) => (*/}
            {/*      <NavItem*/}
            {/*          key={index}*/}
            {/*          isActive={tabActive === item}*/}
            {/*          onClick={() => handleClickTab(item)}*/}
            {/*      >*/}
            {/*        {item}*/}
            {/*      </NavItem>*/}
            {/*  ))}*/}
            {/*</Nav>*/}
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden" />
            <div className="flex justify-end">
              <ArchiveFilterListBox lists={FILTERS} />
            </div>
          </div>

          {/* LOOP ITEMS */}
          {/* LOOP ITEMS POSTS */}
          {/*{tabActive === 'Articles' && (*/}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
            {result
              ? result.data.map((post) => (
                  <CardPost11 key={post.id} post={post} />
                ))
              : null}
          </div>
          {/*)}*/}
          {/* LOOP ITEMS CATEGORIES */}
          {/*{tabActive === 'Categories' && (*/}
          {/*    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 md:gap-8 mt-8 lg:mt-10">*/}
          {/*      {cats.map((cat) => (*/}
          {/*          <CardCategory2 key={cat.id} taxonomy={cat} />*/}
          {/*      ))}*/}
          {/*    </div>*/}
          {/*)}*/}
          {/* LOOP ITEMS TAGS */}
          {/*{tabActive === 'Tags' && (*/}
          {/*    <div className="flex flex-wrap mt-12 ">*/}
          {/*      {tags.map((tag) => (*/}
          {/*          <Tag className="mb-3 mr-3" key={tag.id} tag={tag} />*/}
          {/*      ))}*/}
          {/*    </div>*/}
          {/*)}*/}
          {/* LOOP ITEMS POSTS */}
          {/*{tabActive === 'Authors' && (*/}
          {/*    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 md:gap-8 mt-8 lg:mt-10">*/}
          {/*      {authors.map((author) => (*/}
          {/*          <CardAuthorBox2 key={author.id} author={author} />*/}
          {/*      ))}*/}
          {/*    </div>*/}
          {/*)}*/}

          {/* PAGINATION */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
            {/*<ButtonPrimary>Show me more</ButtonPrimary>*/}
          </div>
        </main>

        {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}
        {/*<div className="relative py-16">*/}
        {/*  <BackgroundSection />*/}
        {/*  <SectionGridCategoryBox*/}
        {/*    categories={DEMO_CATEGORIES.filter((_, i) => i < 10)}*/}
        {/*  />*/}
        {/*  <div className="text-center mx-auto mt-10 md:mt-16">*/}
        {/*    <ButtonSecondary>Show me more</ButtonSecondary>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/* === SECTION 5 === */}
        {/*<SectionSliderNewAuthors*/}
        {/*  heading="Top elite authors"*/}
        {/*  subHeading="Discover our elite writers"*/}
        {/*  authors={DEMO_AUTHORS.filter((_, i) => i < 10)}*/}
        {/*/>*/}

        {/* SUBCRIBES */}
        {/*<SectionSubscribe2 />*/}
      </div>
    </div>
  );
};

export default PageTagSearch;
