import React, { FC, ReactNode, useEffect, useState } from 'react';
import { PostDataType, TaxonomyType } from 'data/templates/types';
import { SINGLE } from 'data/templates/single';
import { CommentType } from 'components/templates/CommentCard/CommentCard';
import { useAppDispatch } from 'app/hooks';
import { changeCurrentPage } from 'app/pages/pages';
import ReactMarkdown from 'react-markdown';
import SingleHeader from './SingleHeader';
import { getSinglePost } from '../../apis/cactus';
import { PostSingleResponse } from '../../apis/cactus/models/PostResponse';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import SingleContent from './SingleContent';
import { SinglePostDataType } from '../../data/types';
import { useParams } from 'react-router-dom';
import SingleNotFoundContent from './SingleNotFoundContent';

export interface PageSingleProps {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  tags: TaxonomyType[];
  content: string | ReactNode;
  comments: CommentType[];
}

const PageSingle: FC<PageSingleProps> = ({ className = '' }) => {
  const dispatch = useAppDispatch();
  const { singleId } = useParams<{ singleId: string }>();
  const [singlePost, setSinglePost] = useState<SinglePostDataType | undefined>(
    undefined
  );
  useEffect(() => {
    (async () => {
      try {
        const data = await getSinglePost(singleId);
        dispatch(
          changeCurrentPage({ type: '/single/:singleId', data: data[0] })
        );
        const tempArr = data.map<SinglePostDataType>((item) => ({
          id: item.id,
          title: item.title,
          createdTime: new Date(item.createdAt),
          coverImageUrl: item.coverImage
            ? item.coverImage.url + '?w=800'
            : undefined,
          topic: {
            id: item.topic.id,
            name: item.topic.name,
          },
          tags: item.tags
            ? item.tags.map((i) => ({
                id: i.id,
                name: i.name,
                colorHex: i.color.hex,
              }))
            : [],
          content: item.mainContent ? item.mainContent : undefined,
          description: item.description ? item.description : undefined,
        }));
        const temp = tempArr[0];
        setSinglePost(temp);
        // UPDATE CURRENT PAGE DATA IN PAGE REDUCERS
      } catch (err) {
        console.log(err);
      }
    })();
    return () => {
      dispatch(changeCurrentPage({ type: '/', data: {} }));
    };
  }, []);
  return (
    <>
      <div
        className={`nc-PageSingleTemplate3 ${className}`}
        data-nc-id="PageSingleTemplate3"
      >
        <header className="relative pt-16 z-10 md:py-20 lg:py-28 bg-neutral-900 dark:bg-black">
          {/* SINGLE HEADER */}
          <div className="dark container relative z-10">
            <div className="max-w-screen-md">
              {singlePost ? (
                <SingleHeader
                  hiddenDesc
                  metaActionStyle="style2"
                  pageData={singlePost}
                />
              ) : null}
            </div>
          </div>

          {/* FEATURED IMAGE */}
          <div className="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3">
            <div className="hidden md:block absolute top-0 left-0 bottom-0 w-1/5 from-neutral-900 dark:from-black bg-gradient-to-r" />
            {singlePost && singlePost.coverImageUrl ? (
              <img
                className="block w-full h-full object-cover"
                src={singlePost.coverImageUrl}
                alt="post cover"
              />
            ) : null}
          </div>
        </header>

        {/* SINGLE MAIN CONTENT */}
        <div className="container mt-10">
          {singlePost ? (
            <SingleContent data={singlePost} />
          ) : (
            <SingleNotFoundContent />
          )}
        </div>
        {/*<div className="container mt-10">*/}
        {/*  <SingleContent data={SINGLE} />*/}
        {/*</div>*/}
      </div>
    </>
  );
};

export default PageSingle;
