import React, { FC, useEffect, useRef } from 'react';
import Tag from 'components/Tag';
import SingleAuthor from './templates/Section/SingleAuthor';
import SingleCommentForm from './templates/Section/SingleCommentForm';
import SingleCommentLists from './templates/Section/SingleCommentLists';
import SingleContentDemo from './templates/Section/SingleContentDemo';
import { useLocation } from 'react-router';
import { CardPostDataType, SinglePostDataType } from '../../data/types';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import SinglePhillyAuthor from './SinglePhillyAuthor';

export interface SingleContentProps {
  data: SinglePostDataType;
}

const SingleContent: FC<SingleContentProps> = ({ data }) => {
  const { tags, content } = data;
  const commentRef = useRef<HTMLDivElement>(null);
  //
  const location = useLocation();

  useEffect(() => {
    //  SCROLL TO COMMENT AREA
    if (location.hash !== '#comment') {
      return;
    }
    //
    if (location.hash === '#comment') {
      setTimeout(() => {
        if (commentRef.current) {
          commentRef.current.scrollIntoView();
        }
      }, 500);
    }
  }, [location]);

  return (
    <div className="nc-SingleContent space-y-10">
      {/* ENTRY CONTENT */}
      <article
        id="single-entry-content"
        className="prose prose-sm prose-pre:!p-0 !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        {content ? (
          <ReactMarkdown
            children={content}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    customStyle={{ margin: 0 }}
                    children={String(children).replace(/\n$/, '')}
                    style={materialOceanic}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={`${className}`} {...props}>
                    {children}
                  </code>
                );
              },
            }}
            remarkPlugins={[remarkGfm]}
          />
        ) : (
          <h1 className="mx-auto p-20">The Content is on Its Way...</h1>
        )}
      </article>

      {/* TAGS */}
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        {tags.map((item) => (
          <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
        ))}
      </div>

      {/* AUTHOR */}
      <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700" />

      <div className="max-w-screen-md mx-auto pb-10">
        <SinglePhillyAuthor />
      </div>
      {/*<div className="max-w-screen-md mx-auto">*/}
      {/*<SingleAuthor author={author} />*/}
      {/*</div>*/}

      {/* COMMENT FORM */}
      {/*<div*/}
      {/*  id="comment"*/}
      {/*  ref={commentRef}*/}
      {/*  className="max-w-screen-md mx-auto pt-5"*/}
      {/*>*/}
      {/*  <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">*/}
      {/*    Responses ({commentCount})*/}
      {/*  </h3>*/}
      {/*  <SingleCommentForm*/}
      {/*    onClickSubmit={(id) => console.log(id)}*/}
      {/*    onClickCancel={(id) => console.log(id)}*/}
      {/*  />*/}
      {/*</div>*/}

      {/* COMMENTS LIST */}
      {/*<div className="max-w-screen-md mx-auto">*/}
      {/*  <SingleCommentLists comments={comments} />*/}
      {/*</div>*/}
    </div>
  );
};

export default SingleContent;
