import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import BgGlassmorphism from '../../components/templates/BgGlassmorphism/BgGlassmorphism';
import { DEMO_CATEGORIES } from '../../data/templates/taxonomies';
import { DEMO_POSTS, DEMO_POSTS_VIDEO } from '../../data/templates/posts';
import { PostDataType as TemplatePostDataType } from '../../data/templates/types';
import SectionTopicsSlider from './SectionTopicsSlider';
import SectionTagsSlider from './SectionTagsSlider';
import SectionPostsGrid from './SectionPostsGrid';
import { getPosts, getTags, getTopics } from '../../apis/cactus';
import { CardPostDataType, TagDataType, TopicDataType } from '../../data/types';

// const POSTS: TemplatePostDataType[] = DEMO_POSTS;
const Index: React.FC = () => {
  const [topics, setTopics] = useState<TopicDataType[]>([]);
  useEffect(() => {
    getTopics().then((data) => {
      const temp = data.map<TopicDataType>((item) => ({
        id: item.id,
        name: item.name,
        coverImageUrl: item.coverImage
          ? item.coverImage.url + '?h=1200'
          : undefined,
        tags: item.tags
          ? item.tags.map((i) => ({
              id: i.id,
              name: i.name,
              colorHex: i.color.hex,
            }))
          : [],
        createdTime: new Date(item.createdAt),
      }));
      setTopics(temp);
    });
  }, []);

  const [tags, setTags] = useState<TagDataType[]>([]);
  useEffect(() => {
    getTags().then((data) => {
      console.log(data);
      const temp = data.map<TagDataType>((item) => ({
        id: item.id,
        name: item.name,
        iconURL: item.iconURL,
        coverImageUrl: item.coverImage
          ? item.coverImage.url + '?h=350'
          : undefined,
        colorHex: item.color.hex,
      }));
      setTags(temp);
    });
  }, []);

  const [posts, setPosts] = useState<CardPostDataType[]>([]);
  useEffect(() => {
    getPosts().then((data) => {
      const temp = data.map<CardPostDataType>((item) => ({
        id: item.id,
        title: item.title,
        createdTime: new Date(item.createdAt),
        coverImageUrl: item.coverImage
          ? item.coverImage.url + '?h=650'
          : undefined,
        topic: item.topic
          ? {
              id: item.topic.id,
              name: item.topic.name,
            }
          : undefined,
        tags: item.tags
          ? item.tags.map((i) => ({
              id: i.id,
              name: i.name,
              colorHex: i.color.hex,
            }))
          : [],
      }));
      setPosts(temp);
    });
  }, []);

  return (
    <div className="nc-PageHome relative">
      <Helmet>
        <title>Home || Philly's Fun Blog</title>
      </Helmet>

      <div className="relative overflow-hidden">
        <BgGlassmorphism />

        <div className="container relative">
          {/* === SECTION  === */}
          <SectionTopicsSlider
            heading="Philly's Blog"
            className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-24"
            topics={topics.filter((_, i) => i < 3)}
          />
          {/* === SECTION 5 === */}
          <SectionTagsSlider
            className="py-16 lg:py-24"
            heading="Top Trending Tags"
            subHeading="Explore the Edge of the World!"
            tags={tags}
            cardTagType="card4"
          />
        </div>

        <div className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100">
          <div className="relative container">
            <SectionPostsGrid
              className="py-16 lg:py-28"
              headingIsCenter
              postCardName="card9"
              heading="Explore latest articles"
              subHeading="Read the Latest Post or Explore More 🥡"
              posts={posts.filter((_, i) => i < 6)}
              gridClass="md:grid-cols-2 lg:grid-cols-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
