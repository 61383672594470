import client from './config';
import { PostResponse, PostSingleResponse } from './models/PostResponse';
import { TagResponse } from './models/TagResponse';
import { TopicResponse } from './models/TopicResponse';

export default client;

const postResponseProjection = `
    {
    "id": _id,
    title,
    description,
    mainContent,
    "coverImage": coverImage.asset->{assetId, url},
    topic->{"id": _id, name},
    tags[]->{"id": _id, name, color},
    "createdAt": _createdAt
    }`;

const tagResponseProjection = `
    {
     "id": _id,
     name,
     iconURL,
     "coverImage": coverImage.asset->{assetId, url},
     "createdAt": _createdAt,
     color
    }
`;

const topicResponseProjection = `
    {
     "id": _id,
     name,
     "coverImage": coverImage.asset->{assetId, url},
     "createdAt": _createdAt,
     tags[]->{"id": _id, name,color}
    }
`;

export async function getSinglePost(id: string) {
  const query =
    `*[_type == "post" && _id == "${id}"]` +
    '{' +
    ' "id": _id,' +
    ' title,' +
    ' description,' +
    ' mainContent,' +
    ' "coverImage": coverImage.asset->{assetId, url},' +
    ' topic->{"id": _id, name},' +
    ' tags[]->{"id": _id, name, color},' +
    ' "createdAt": _createdAt' +
    '}';
  return client.fetch<PostSingleResponse[]>(query);
}

export async function getPosts(id?: string) {
  const filterQuery = `
    ${id ? `&& _id == "${id}"` : ''}
  `;
  const query = `*[_type == "post" ${filterQuery}]${postResponseProjection}`;
  return client.fetch<PostResponse[]>(query);
}

export async function getTags(id?: string) {
  const filterQuery = `
    ${id ? `&& _id == "${id}"` : ''}
  `;
  const query = `*[_type == "tag" ${filterQuery}]${tagResponseProjection}`;
  return client.fetch<TagResponse[]>(query);
}

export async function getTopics(id?: string) {
  const filterQuery = `
    ${id ? `&& _id == "${id}"` : ''}
  `;
  const query = `*[_type == "topic" ${filterQuery}]${topicResponseProjection}`;
  return client.fetch<TopicResponse[]>(query);
}

export async function getPostsByPagination(
  pagination: {
    from: number;
    size: number;
  },
  conditional?: {
    tagId?: string;
    topicId?: string;
  },
  sort?: {
    byField: string;
    inDescending: boolean;
  }
) {
  const { from, size } = pagination;
  const filterQuery = `
  *[_type == 'post'
    ${
      conditional
        ? `${
            conditional.topicId
              ? `&& topic->_id == "${conditional.topicId}"`
              : ''
          }` +
          `${
            conditional.tagId ? `&& "${conditional.tagId}" in tags[]->_id ` : ''
          }`
        : ''
    }
  ]
  `;
  const sortQuery = `${
    sort
      ? ` | order(${sort.byField} ${sort.inDescending ? 'desc' : 'asc'})`
      : ''
  }
  `;
  const paginationQuery = `
    [${from}..${from + size - 1}]
  `;
  const query = `{
  "data": ${filterQuery}${postResponseProjection}${sortQuery}${paginationQuery},
  "size": count(${filterQuery})
}`;
  return client.fetch<{
    data: PostResponse[];
    size: number;
  }>(query);
}
