import React, { FC } from 'react';
import Avatar from 'components/templates/Avatar/Avatar';
import { PostDataType } from 'data/templates/types';
import { Link } from 'react-router-dom';
import { CardPostDataType } from '../../data/types';

export interface PostMeta2Props {
  className?: string;
  meta: Pick<CardPostDataType, 'createdTime' | 'tags'>;
  hiddenCategories?: boolean;
  size?: 'large' | 'normal';
  avatarRounded?: string;
}

const PostMeta2: FC<PostMeta2Props> = ({
  className = 'leading-none',
  meta,
  hiddenCategories = false,
  size = 'normal',
  avatarRounded,
}) => {
  const { tags, createdTime } = meta;
  return (
    <div
      className={`nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 ${
        size === 'normal' ? 'text-xs' : 'text-sm'
      } ${className}`}
      data-nc-id="PostMeta2"
    >
      <Link to="#" className="flex items-center space-x-2">
        <Avatar
          radius={avatarRounded}
          sizeClass={
            size === 'normal'
              ? 'h-6 w-6 text-sm'
              : 'h-10 w-10 sm:h-11 sm:w-11 text-xl'
          }
          imgUrl="https://avatars.githubusercontent.com/u/54149482?v=4"
          userName="Philly Chien"
        />
      </Link>
      <div className="ml-3">
        <div className="flex items-center">
          <Link to="#" className="block font-semibold">
            Philly Chien
          </Link>

          {!hiddenCategories && (
            <>
              <span className="mx-2 font-semibold">·</span>
              <div className="ml-0">
                <span className="text-xs">🏷 </span>
                {tags.map((tag, index) => (
                  <Link key={tag.id} to="#" className="font-semibold">
                    {tag.name}
                    {index < tags.length - 1 && <span>, </span>}
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="text-xs mt-[6px]">
          <span className="text-neutral-700 dark:text-neutral-300">
            {createdTime.toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })}
          </span>
          {/*<span className="mx-2 font-semibold">·</span>*/}
          {/*<span className="text-neutral-700 dark:text-neutral-300">*/}
          {/*  {readingTime} min read*/}
          {/*</span>*/}
        </div>
      </div>
    </div>
  );
};

export default PostMeta2;
