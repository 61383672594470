import { PostActionDropdownProps } from 'components/templates/PostActionDropdown/PostActionDropdown';
import BookmarkContainer from 'containers/BookmarkContainer/BookmarkContainer';
import { PostDataType } from 'data/templates/types';
import React, { FC } from 'react';

export interface PostCardSaveActionProps
  extends Pick<PostActionDropdownProps, 'dropdownPositon'> {
  className?: string;
  classBgIcon?: string;
  readingTime?: number;
  hiddenReadingTime?: boolean;
  postData: PostDataType;
}

const PostCardSaveAction: FC<PostCardSaveActionProps> = ({
  className = '',
  hiddenReadingTime = false,
  classBgIcon,
  readingTime,
  postData,
}) => {
  const { bookmark, id } = postData;

  return (
    <div
      className={`nc-PostCardSaveAction flex items-center space-x-2 text-xs text-neutral-700 dark:text-neutral-300 ${className}`}
      data-nc-id="PostCardSaveAction"
    >
      {!hiddenReadingTime && !!readingTime && (
        <span>{readingTime} min read</span>
      )}

      <BookmarkContainer
        initBookmarked={bookmark.isBookmarked}
        containerClassName={classBgIcon}
        postId={id}
      />
    </div>
  );
};

export default PostCardSaveAction;
