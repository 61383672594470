import React, { FC } from 'react';

const SingleNotFoundContent: FC = () => {
  return (
    <div className="nc-SingleContent space-y-10">
      {/* ENTRY CONTENT */}
      <article
        id="single-entry-content"
        className="prose prose-sm prose-pre:!p-0 !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <div className="px-10 py-36">
          <h1>Try Other Posts Again? 👐</h1>
          <span>
            Sorry, we cannot find the post you want. Probably, it has been
            deleted...
          </span>
        </div>
      </article>
    </div>
  );
};

export default SingleNotFoundContent;
