import React from 'react';
import { Link } from 'react-router-dom';
import logoImg from 'images/logo.png';
import logoLightImg from 'images/logo-light.png';
import { ReactComponent as LogoCoder } from 'images/logo-coder.svg';

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
}) => {
  return (
    <Link to="/" className="ttnc-logo inline-block text-primary-600">
      {/* THIS USE FOR MY MULTI DEMO */}
      {/* IF YOU ARE MY CLIENT. PLEASE DELETE THIS CODE AND YOU YOUR IMAGE PNG BY BELLOW CODE */}
      <LogoCoder />
      {/* Note: below is template default logo */}
      {/*<LogoSvg />*/}
    </Link>
  );
};

export default Logo;
