import React, { FC } from 'react';
import NcImage from 'components/templates/NcImage/NcImage';
import { TaxonomyType } from 'data/templates/types';
import { Link } from 'react-router-dom';
import { TagDataType } from '../../data/types';

export interface CardTag3Props {
  className?: string;
  tag: TagDataType;
}

const CardTag3: FC<CardTag3Props> = ({ className = '', tag }) => {
  const { id, name, colorHex, iconURL, coverImageUrl } = tag;
  return (
    <Link
      to={`/search/tag/${id}`}
      className={`nc-CardCategory3 flex flex-col ${className}`}
      data-nc-id="CardCategory3"
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={coverImageUrl}
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity" />
      </div>
      <div className="mt-4 truncate flex">
        <h2
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-semibold truncate`}
        >
          {name}
        </h2>
        {/*        <span*/}
        {/*            className={`block mt-2 text-sm text-neutral-600 dark:text-neutral-400`}*/}
        {/*        >*/}
        {/*  {count} Articles*/}
        {/*</span>*/}
      </div>
    </Link>
  );
};

export default CardTag3;
