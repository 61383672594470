import React, { FC } from 'react';
import NcImage from 'components/NcImage';
import { TagDataType } from 'data/types';
import { NavLink } from 'react-router-dom';

export interface CardTag1Props {
  className?: string;
  tag: TagDataType;
  size?: 'large' | 'normal';
}

const CardTag1: FC<CardTag1Props> = ({
  className = '',
  size = 'normal',
  tag,
}) => {
  const {
    id,
    name,
    colorHex,
    coverImageUrl,
    // count, name, href = '/', thumbnail
  } = tag;
  return (
    <NavLink
      to={`/search/tag/${id}`}
      className={`nc-CardCategory1 flex items-center ${className}`}
      data-nc-id="CardCategory1"
    >
      <NcImage
        containerClassName={`flex-shrink-0 ${
          size === 'large' ? 'w-20 h-20' : 'w-12 h-12'
        } rounded-lg mr-4 overflow-hidden`}
        src={coverImageUrl}
      />
      <div>
        <h2
          className={`${
            size === 'large' ? 'text-lg' : 'text-base'
          } nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold`}
        >
          {name}
        </h2>
        {/*<span*/}
        {/*  className={`${*/}
        {/*    size === 'large' ? 'text-sm' : 'text-xs'*/}
        {/*  } block mt-[2px] text-neutral-500 dark:text-neutral-400`}*/}
        {/*>*/}
        {/*  {count} Articles*/}
        {/*</span>*/}
      </div>
    </NavLink>
  );
};

export default CardTag1;
