import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// STYLE
import './styles/main.scss';
import './fonts/line-awesome-1.3.0/css/line-awesome.css';
// TODO: need to fix the circular dependency error,
//       and it left commented out for now.
// const RtlImportCssLazy = React.lazy(() => import('RtlImportCss'));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />

      {/*TODO: need to fix the circular dependency error, and left commented out for now.*/}
      {/**/}
      {/* LOAD RTL CSS WHEN RTL MODE ENABLE */}
      {/*{process.env.REACT_APP_LRT_OR_RTL === 'rtl' && (*/}
      {/*  <Suspense fallback={<div />}>*/}
      {/*    <RtlImportCssLazy />*/}
      {/*  </Suspense>*/}
      {/*)}*/}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
