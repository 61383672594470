import React, { FC, ReactNode, useEffect } from 'react';
import { PostDataType, TaxonomyType } from 'data/templates/types';
import NcImage from 'components/templates/NcImage/NcImage';
import { SINGLE } from 'data/templates/single';
import SingleContent from '../Section/SingleContent';
import { CommentType } from 'components/templates/CommentCard/CommentCard';
import { useAppDispatch } from 'app/hooks';
import { changeCurrentPage } from 'app/pages/pages';
import SingleHeader from '../Section/SingleHeader';
import SingleRelatedPosts from '../Section/SingleRelatedPosts';

export interface PageSingleProps {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  tags: TaxonomyType[];
  content: string | ReactNode;
  comments: CommentType[];
}

const PageSingleDemo: FC<PageSingleProps> = ({ className = '' }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // UPDATE CURRENT PAGE DATA IN PAGE-REDUCERS
    dispatch(
      changeCurrentPage({ type: '/developers/single/:slug', data: SINGLE })
    );

    return () => {
      dispatch(changeCurrentPage({ type: '/', data: {} }));
    };
  }, []);

  return (
    <>
      <div
        className={`nc-PageSingle pt-8 lg:pt-16 ${className}`}
        data-nc-id="PageSingle"
      >
        {/* SINGLE HEADER */}
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <SingleHeader pageData={SINGLE} />
          </div>
        </header>

        {/* FEATURED IMAGE */}
        <NcImage
          containerClassName="container my-10 sm:my-12"
          className="object-cover w-full h-full rounded-xl"
          src={SINGLE.featuredImage}
        />

        {/* SINGLE MAIN CONTENT */}
        <div className="container">
          <SingleContent data={SINGLE} />
        </div>

        {/* RELATED POSTS */}
        <SingleRelatedPosts />
      </div>
    </>
  );
};

export default PageSingleDemo;
