import sanityClient from '@sanity/client';

const client = sanityClient({
  projectId: 'yyea5c1k',
  dataset: 'staging',
  apiVersion: '2021-06-07',
  token: '',
  useCdn: false,
});

export default client;
