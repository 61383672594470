import React, { FC } from 'react';
import { CardPostDataType } from 'data/types';
import { Link } from 'react-router-dom';
import Avatar from 'components/templates/Avatar/Avatar';

export interface CardPhillyAuthorProps
  extends Pick<CardPostDataType, 'createdTime'> {
  className?: string;
  createdTime: CardPostDataType['createdTime'];
  hoverReadingTime?: boolean;
}

const CardPhillyAuthor: FC<CardPhillyAuthorProps> = ({
  className = '',
  createdTime,
  hoverReadingTime = true,
}) => {
  return (
    <Link
      to="#"
      className={`nc-CardAuthor2 relative inline-flex items-center ${className}`}
      data-nc-id="CardAuthor2"
    >
      <Avatar
        sizeClass="h-10 w-10 text-base"
        containerClassName="flex-shrink-0 mr-3"
        radius="rounded-full"
        imgUrl="https://avatars.githubusercontent.com/u/54149482?v=4"
        userName="Philly Chien"
      />
      <div>
        <h2
          className={`text-sm text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium`}
        >
          Philly Chien
        </h2>
        <span
          className={`flex items-center mt-1 text-xs text-neutral-500 dark:text-neutral-400`}
        >
          <span>
            {createdTime.toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })}
          </span>
          {/*{readingTime && (*/}
          {/*  <>*/}
          {/*    <span*/}
          {/*      className={`hidden lg:inline mx-1 transition-opacity ${*/}
          {/*        hoverReadingTime ? 'opacity-0 group-hover:opacity-100' : ''*/}
          {/*      }`}*/}
          {/*    >*/}
          {/*      ·*/}
          {/*    </span>*/}
          {/*    <span*/}
          {/*      className={`hidden lg:inline transition-opacity ${*/}
          {/*        hoverReadingTime ? 'opacity-0 group-hover:opacity-100' : ''*/}
          {/*      }`}*/}
          {/*    >*/}
          {/*      {readingTime} min read*/}
          {/*    </span>*/}
          {/*  </>*/}
          {/*)}*/}
        </span>
      </div>
    </Link>
  );
};

export default CardPhillyAuthor;
