import React, { FC, useState } from 'react';
import PostCardSaveAction from 'components/templates/PostCardSaveAction/PostCardSaveAction';
import { PostDataType } from 'data/templates/types';
import { Link } from 'react-router-dom';
import CategoryBadgeList from 'components/templates/CategoryBadgeList/CategoryBadgeList';
import PostCardLikeAndComment from 'components/templates/PostCardLikeAndComment/PostCardLikeAndComment';
import PostCardMeta from 'components/PostCardMeta';
import PostFeaturedMedia from 'components/templates/PostFeaturedMedia/PostFeaturedMedia';
import { CardPostDataType } from '../../data/types';
import TagBadgeList from '../TagBadgeList';
import NcImage from '../templates/NcImage/NcImage';

export interface CardPost11Props {
  className?: string;
  post: CardPostDataType;
  ratio?: string;
  hiddenAuthor?: boolean;
}

const CardPost11: FC<CardPost11Props> = ({
  className = 'h-full',
  post,
  hiddenAuthor = false,
  ratio = 'aspect-w-4 aspect-h-3',
}) => {
  const { id, title, tags, createdTime, coverImageUrl } = post;

  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`nc-Card11 relative flex flex-col group [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="Card11"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      //
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-xl overflow-hidden ${ratio}`}
      >
        <div>
          <NcImage containerClassName="absolute inset-0" src={coverImageUrl} />
          {/*<PostFeaturedMedia post={post} isHover={isHover} />*/}
        </div>
      </div>
      <Link to={`/single/${id}`} className="absolute inset-0" />
      <span className="absolute top-3 inset-x-3">
        <TagBadgeList tags={tags} />
        {/*<CategoryBadgeList categories={categories} />*/}
      </span>

      <div className="p-4 flex flex-col flex-grow space-y-3">
        {!hiddenAuthor ? (
          <PostCardMeta meta={post} />
        ) : (
          <span className="text-xs text-neutral-500">
            {createdTime.toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })}
          </span>
        )}
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100 ">
          <Link to={`/single/${id}`} className="line-clamp-2" title={title}>
            {title}
          </Link>
        </h2>
        {/*<div className="flex items-end justify-between mt-auto">*/}
        {/*    <PostCardLikeAndComment className="relative" postData={post} />*/}
        {/*    <PostCardSaveAction className="relative" postData={post} />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default CardPost11;
